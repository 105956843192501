<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import moment from 'moment'
import { getAction } from '@/command/netTool'
export default {
  name: 'liveList',
  data() {
    return {
      ...api.command.getState(),
      liveRoom: []
    }
  },
  mounted() {
    getAction('/market/farmLiveRoom/liveRoomList').then(res => {
      this.liveRoom = res.data.map(e => {
        return {
          ...e,
          value: e.id
        }
      })
    })

    api.command.getList.call(this, {
      url: '/market/farmLiveRoom/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '开播时间',
          type: 'rangePicker',
          keys: ['startTime', 'endTime'],
          showTime: true
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'startTime',
          title: '开播时间',
          align: 'left',
          customRender: function(text, records) {
            return `${moment(records.startTime).format('YYYY-MM-DD HH:mm')}~${moment(records.endTime).format(
              'YYYY-MM-DD HH:mm'
            )}`
          },
          onExport: (text, records) => {
            return `${moment(records.startTime).format('YYYY-MM-DD HH:mm')}~${moment(records.endTime).format(
              'YYYY-MM-DD HH:mm'
            )}`
          }
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '未开始',
              value: '0'
            },
            {
              text: '直播中',
              value: '1'
            },
            {
              text: '已结束',
              value: '2'
            }
          ],
          onExport: records => {
            return ['未开始', '直播中', '已结束'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: {
                '0': '未开始',
                '1': '直播中',
                '2': '已结束'
              }[data],
              color: {
                '0': '#FFA500',
                '1': '#18BE6B',
                '2': '#ED4014'
              }[data]
            }
          }
        },
        {
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPostArr.call(this, {
                    url: '/market/farmLiveRoom/deleteBatch',
                    params: {
                      idList: [records.id]
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handleStatePopup()
          }
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmLiveRoom/upBatch',
                  params: {
                    idList: idList
                  }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmLiveRoom/downBatch',
                  params: {
                    idList: idList
                  }
                })
              }
            },
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmLiveRoom/deleteBatch',
                  params: {
                    idList: idList
                  }
                })
              }
            }
          ]
        }
      ]
    },
    getDrawerForm(source, formData) {
      const formArr = [
        {
          form: [
            {
              name: '直播间',
              type: 'select',
              key: 'liveRoomId',
              typeData: this.liveRoom
            },
            {
              name: '排序号',
              type: 'input',
              key: 'sort'
            }
          ]
        }
      ]
      return formArr
    },
    handleStatePopup(
      source = {
        sort: ''
      }
    ) {
      apiTool.showDrawer({
        title: '直播间选择',
        width: '720px',
        view: DrawerForm,
        width: '600px',
        viewProps: {
          form: source,
          data: formData => this.getDrawerForm(source, formData)
        },
        success: ({ data, setHidden }) => {
          if (!(data.liveRoomId && data.sort)) {
            this.$message.warning('请完善信息！')
            return
          }
          api.command.create
            .call(this, {
              url: '/market/farmLiveRoom/add',
              params: data
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style scoped>
p {
  border-radius: 0%;
  border: 1px solid;
}
</style>
